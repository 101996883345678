<template>
	<ContentLenses />
	<div>
		<ModalLensesAdd />
	</div>
</template>

<script>
	import ContentLenses from "@/components/content/ContentLenses.vue"
	import ModalLensesAdd from "@/components/modals/ModalLensesAdd.vue"
	
	import { defineComponent } from 'vue'
	
	export default defineComponent({
		name: 'LensesView',
		components: {
			ContentLenses,
			ModalLensesAdd
		}
	})
</script>
