<template>
	<ContentPhotos />
	<div>
		<ModalPhotosAdd />
		<ModalFilmsChange />
	</div>
</template>

<script>
	import ContentPhotos from "@/components/content/ContentPhotos.vue"
	import ModalPhotosAdd from "@/components/modals/ModalPhotosAdd.vue"
	import ModalFilmsChange from "@/components/modals/ModalFilmsChange.vue"
	
	import { defineComponent } from 'vue'
	
	export default defineComponent({
		name: 'PhotosView',
		components: {
			ContentPhotos,
			ModalPhotosAdd,
			ModalFilmsChange,
		}
	})
</script>
