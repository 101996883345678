<template>
	<div 
		class="modal fade" 
		role="dialog" 
		tabindex="-1" 
		id="modal__film__add"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
	>
		<div 
			class="modal-dialog modal-dialog-centered modal-dialog-scrollable py-4" 
			role="document"
		>
			<div class="modal-content overflow-hidden">
				<div class="modal-body bg-secondary p-0">
					<div class="table-responsive bg-white">
						<table class="table table-striped mb-0">
							<tbody>
								<tr>
									<td class="pe-2">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Name') }}</span>
											<IconLabelMandatory v-if="!data.name" />
											<IconLabelCheck v-else />
										</label>
										<input 
											class="form-control-lg form-control rounded-pill" 
											type="text" 
											:placeholder="lang.get('Film name')"
											v-model="data.name"
										>
									</td>
									<td class="ps-2 w--90">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Roll') }}</span>
											<IconLabelMandatory v-if="!data.roll" />
											<IconLabelCheck v-else />
										</label>
										<input 
											class="bg-white form-control-lg form-control text-center px-2 py--1 fs--large fw--300" 
											type="text" 
											pattern="\d*"
											placeholder="#"
											v-model="data.roll"
										>
									</td>
								</tr>
								<template v-if="ds.count.emulsions">
									<tr>
										<td colspan="2">
											<label class="form-label fw-bold d-block">{{ lang.get('Emulsion') }}</label>
											<select 
												class="form-select rounded-pill"
												v-model="data.id_emulsion"
											>
												<option value="" selected="">{{ lang.get('Select…') }}</option>
												<template 
													v-for="(emulsion, index) in ds_emulsions.items"
													:key="index"
												>
													<template v-if="emulsion != null">
														<option 
															:value="emulsion.id_emulsion"
														>{{ emulsion.name }}</option>
													</template>
												</template>
											</select>
										</td>
									</tr>
								</template>
								<template v-if="ds.count.cameras">
									<tr>
										<td colspan="2">
											<label class="form-label fw-bold d-block">{{ lang.get('Camera') }}</label>
											<select 
												class="form-select rounded-pill"
												v-model="data.id_camera"
											>
												<option value="" selected="">{{ lang.get('Select…') }}</option>
												<template 
													v-for="(camera, index) in ds_cameras.items"
													:key="index"
												>
													<template v-if="camera != null">
														<option 
															:value="camera.id_camera"
														>{{ camera.name }}</option>
													</template>
												</template>
											</select>
										</td>
									</tr>
								</template>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Expiration date') }}</label>
										<input 
											class="form-control py--6 rounded-pill" 
											type="date"
											v-model="data.date_expiration"
										>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Starts on') }}</label>
										<input 
											class="form-control py--6 rounded-pill" 
											type="date"
											v-model="data.date_start"
										>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Completed on') }}</label>
										<input 
											class="form-control py--6 rounded-pill" 
											type="date"
											v-model="data.date_completed"
										>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Develop on') }}</label>
										<input 
											class="form-control py--6 rounded-pill" 
											type="date"
											v-model="data.date_develop"
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer p-0 bg-white border-0">
					<ul class="nav nav-pills nav-justified m-0 w-100">
						<template v-if="ds_options.params.action === 'add'">
							<li class="nav-item">
								<button 
									class="nav-link fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100" 
									v-on:click="addFilm()"
									:disabled="disabled"
									:class="(disabled === true) ? 'bg-secondary text-primary text-opacity-25' : 'bg-primary text-white'"
								>
									<IconBtnPlus />
									<span>{{ lang.get('Add Film') }}</span>
								</button>
							</li>
						</template>
						<template v-if="ds_options.params.action === 'edit'">
							<li class="nav-item">
								<button 
									class="nav-link fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100"
									v-on:click="updateFilm()"
									:disabled="disabled"
									:class="(disabled === true) ? 'bg-secondary text-primary text-opacity-25' : 'bg-primary text-white'"
								>
									<IconBtnRefresh />
									<span>{{ lang.get('Save changes') }}</span>
								</button>
							</li>
						</template>
						<li class="nav-item">
							<button 
								class="nav-link bg-secondary text-primary text-opacity-50 fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100"
								data-bs-target="#modal__film__add" 
								data-bs-toggle="modal"
								id="btn__modal_film_add__close"
								v-on:click="closeForm()"
							>
								<IconBtnCloseSmall />
								<span>{{ lang.get('Close') }}</span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent, ref, watch } from 'vue';

	import IconBtnPlus from "@/assets/icons/IconBtnPlus.vue"
	import IconBtnCloseSmall from "@/assets/icons/IconBtnCloseSmall.vue"
	import IconBtnRefresh from "@/assets/icons/IconBtnRefresh.vue"
	import IconLabelMandatory from "@/assets/icons/IconLabelMandatory.vue"
	import IconLabelCheck from "@/assets/icons/IconLabelCheck.vue"
	
	import { db } from "@/databases/db";
	
	import { useDataStore } from '@/stores/data'
	import { useEmulsionsDataStore } from '@/stores/emulsions_data'
	import { useCamerasDataStore } from '@/stores/cameras_data'
	import { useOptionsDataStore } from '@/stores/options_data'
	import { useActionDataStore } from '@/stores/action_data'
	import { useLangStore } from '@/stores/lang'
	
	export default defineComponent({
		name: 'ModalFilmsAdd',
		components: {
			IconBtnPlus,
			IconBtnCloseSmall,
			IconBtnRefresh,
			IconLabelMandatory,
			IconLabelCheck
		},
		setup() {
			const ds = useDataStore()
			const ds_emulsions = useEmulsionsDataStore()
			const ds_cameras = useCamerasDataStore()
			const ds_options = useOptionsDataStore()
			const ds_action = useActionDataStore()
			const lang = useLangStore()
			
			const data = ref({})
			const disabled = ref(null)
			
			watch (ds_options, () => {
				// Add new
				if (ds_options.params.action === 'add') {
					clearForm()
				}
				
				// Edit element
				else if (ds_options.params.action === 'edit') {
					try {
						db.films
						.where('id_film')
						.equals(ds_options.params.id)
						.first()
						.then((film) => {
							data.value.name = film.name
							data.value.roll = film.roll
							data.value.id_emulsion = film.id_emulsion
							data.value.id_camera = film.id_camera
							data.value.date_expiration = film.date_expiration
							data.value.date_start = film.date_start
							data.value.date_completed = film.date_completed
							data.value.date_develop = film.date_develop
						})
					} catch (error) {
						console.log('Error!')
					}
				}
			})
			
			async function updateFilm() {
				try {
					await db.films.update(
						ds_options.params.id,
						{
							name: data.value.name,
							roll: data.value.roll,
							id_emulsion: data.value.id_emulsion,
							id_camera: data.value.id_camera,
							date_expiration: data.value.date_expiration,
							date_start: data.value.date_start,
							date_completed: data.value.date_completed,
							date_develop: data.value.date_develop,
						}
					).then(
						ds_action.updateProcess('films', 'update')
					)
				} catch (error) {
					console.log('Error!')
				}
				
				document.getElementById('btn__modal_film_add__close').click();
			}
			
			
			watch (data.value, () => {
				disabled.value = (
					data.value.name == null || data.value.name === ''
					|| data.value.roll == null || data.value.roll === ''
				)
			})
			
			
			async function addFilm() {
				try {
					await db.films.add({
						name: data.value.name,
						roll: data.value.roll,
						id_emulsion: data.value.id_emulsion,
						id_camera: data.value.id_camera,
						date_expiration: data.value.date_expiration,
						date_start: data.value.date_start,
						date_completed: data.value.date_completed,
						date_develop: data.value.date_develop,
					}).then(
						ds_action.updateProcess('films', 'add')
					)
				} catch (error) {
					console.log('Error!')
				}
				
				document.getElementById('btn__modal_film_add__close').click();
			}
			
			function clearForm() {
				data.value.name = null
				data.value.roll = null
				data.value.id_emulsion = ''
				data.value.id_camera = ''
				data.value.date_expiration = null
				data.value.date_start = null
				data.value.date_completed = null
				data.value.date_develop = null
				
				disabled.value = true
			}
			
			function closeForm() {
				clearForm()
				ds_options.reset()
			}
			
			
			return { 
				ds,
				data, addFilm, disabled,
				db,
				ds_emulsions,
				ds_cameras,
				closeForm,
				ds_options,
				updateFilm,
				lang
			}
		}
	})
</script>