<template>
	<div 
		class="modal fade" 
		role="dialog" 
		tabindex="-1" 
		id="modal__films"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
	>
		<div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable" role="document">
			<div class="modal-content overflow-hidden">
				<div class="modal-body shadow-sm p-0">
					<div class="overflow-hidden">
						<div 
							class="list-group rounded-0 m--n1" 
							data-bs-toggle="modal" 
							data-bs-target="#modal__films"
						>
							<template
								v-for="film in ds_films.items"
								:key="film.id_film"
							>
								<button
									class="list-group-item list-group-item-action d-flex justify-content-center py-3"
									:class="(film.id_film === ds_active.film.id) ? 'active' : ''"
									data-bs-toggle="modal"
									data-bs-target="#modal__films"
									v-on:click="activateFilm(film.id_film, film.name)"
								>
									<span class="text-uppercase fs-6 fw-normal">{{ film.name }}</span>
								</button>
							</template>
							<button 
								class="list-group-item list-group-item-action d-flex justify-content-center py-3" 
								:class="(ds_active.film.id === '') ? 'active' : ''"
								data-bs-toggle="modal" 
								data-bs-target="#modal__films"
								v-on:click="activateFilm('', '')"
							>
								<span class="text-uppercase fs-6 fw-normal">{{ lang.get('Show Photos without Film') }}</span>
							</button>
						</div>
					</div>
				</div>
				<div class="modal-footer p-0">
					<ul class="nav nav-pills nav-justified m-0 w-100">
						<li class="nav-item">
							<button
								class="nav-link bg-secondary m-0 rounded-0 py-3 w-100"
								data-bs-target="#modal__films" 
								data-bs-toggle="modal"
							>{{ lang.get('Close') }}</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	
	import { useFilmsDataStore } from '@/stores/films_data'
	import { useActiveDataStore } from '@/stores/active'
	import { useActionDataStore } from '@/stores/action_data'
	import { useLangStore } from '@/stores/lang'
	
	export default defineComponent({
		name: 'ModalFilmsChange',
		setup() {
			const ds_films = useFilmsDataStore()
			const ds_active = useActiveDataStore()
			const ds_action = useActionDataStore()
			const lang = useLangStore()
			
			function activateFilm(id, name) {
				ds_active.film.id = id
				ds_active.film.name = name
				ds_action.updateProcess('films', 'change')
			}
			
			
			return {
				ds_films,
				ds_active,
				activateFilm,
				lang
			}
		}
	})
</script>