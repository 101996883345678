<template>
	<main>
		<div class="py-3">
			<div class="container-fluid">
				<template v-if="ds.count.photos > 0">
					<div class="row gx-3 gy-3 row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-4">
						<template
							v-for="photo in ds_photos.items"
							:key="photo.id_photo"
						>
							<div class="col">
								<div class="card">
									<div class="card-header text-white bg-primary pe-3">
										<div class="row align-items-center">
											<div class="col">
												<h5 class="text-truncate mb-0">{{ photo.name }}</h5>
												<template
													v-if="
														photo.id_film !== ''
														&& photo.id_camera !== ''
													"
												>
													<p
														class="
															fw-bold text-white-50 mb-0 small line-height-none mt-1
															d-flex flex-wrap mx-n1 mb-n1
														"
													>
														<template v-if="photo.id_film !== ''">
															<span class="d-block mx-1 mb-1 text-nowrap">
																{{ ds_films.items.find((film) => {
																	return film.id_film === photo.id_film
																}).name }}
															</span>
														</template>
														<template v-if="photo.id_camera !== ''">
															<span class="d-block mx-1 mb-1 text-nowrap">
																{{ ds_cameras.items.find((camera) => {
																	return camera.id_camera === photo.id_camera
																}).name }}
															</span>
														</template>
													</p>
												</template>
											</div>
											<div
												class="col-auto"
												v-if="photo.frame"
											>
												<span
													class="fs-2 fw-lighter text-white-50"
												>#{{ photo.frame }}</span>
											</div>
										</div>
									</div>
									<div class="card-body shadow-sm p-0 position-relative">
										<div class="table-responsive">
											<table class="table table-sm mb-0">
												<tbody class="small">
													<tr>
														<th class="fw-bold">{{ lang.get('Taken') }}</th>
														<td class="text-end">
															{{ moment(photo.taken).format("DD.MM.YYYY") }}<br />
															{{ moment(photo.taken).format("HH:mm") }}
														</td>
													</tr>
													<tr>
														<th class="fw-bold">{{ lang.get('Mode') }}</th>
														<td class="text-end">{{ lang.get(photo.mode) }}</td>
													</tr>
													<tr>
														<th class="fw-bold">{{ lang.get('Aperture') }}</th>
														<td class="text-end">{{ photo.aperture }}</td>
													</tr>
													<tr>
														<th class="fw-bold">{{ lang.get('Shutter speed') }}</th>
														<td class="text-end">{{ photo.shutter }}</td>
													</tr>
													<tr>
														<th class="fw-bold">{{ lang.get('Focal length') }}</th>
														<td class="text-end">{{ photo.focal }}</td>
													</tr>
													<tr>
														<th class="fw-bold">{{ lang.get('EV correction') }}</th>
														<td class="text-end">{{ photo.ev }}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div
											class="accordion accordion-flush"
											role="tablist"
											:id="`accordion-${photo.id_photo}`"
										>
											<div class="accordion-item">
												<h2 class="accordion-header" role="tab">
													<button
														class="accordion-button collapsed bg-light py-2"
														type="button"
														data-bs-toggle="collapse"
														:data-bs-target="`#accordion-${photo.id_photo} .item-1`"
													>
														<span class="fw-bold fs--small ft--body ms--n2">{{ lang.get('More') }}</span>
													</button>
												</h2>
												<div
													class="accordion-collapse collapse item-1"
													role="tabpanel"
												>
													<div class="accordion-body p-0">
														<div class="table-responsive">
															<table class="table table-sm mb-0">
																<tbody class="small">
																	<template v-if="photo.id_film !== ''">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Film') }}</th>
																			<td
																				class="text-end"
																			>
																				{{ ds_films.items.find((film) => {
																					return film.id_film === photo.id_film
																				}).name }}
																			</td>
																		</tr>
																	</template>
																	<template v-if="photo.id_camera !== ''">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Camera') }}</th>
																			<td
																				class="text-end"
																			>
																				{{ ds_cameras.items.find((camera) => {
																					return camera.id_camera === photo.id_camera
																				}).name }}
																			</td>
																		</tr>
																	</template>
																	<template v-if="photo.id_lens !== ''">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Lens') }}</th>
																			<td
																				class="text-end"
																			>
																				{{ ds_lenses.items.find((lens) => {
																					return lens.id_lens === photo.id_lens
																				}).name }}
																			</td>
																		</tr>
																	</template>
																	<template v-if="photo.metering">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Light metering') }}</th>
																			<td class="text-end">{{ lang.get(photo.metering) }}</td>
																		</tr>
																	</template>
																	<template v-if="photo.area">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Metering on') }}</th>
																			<td class="text-end">{{ lang.get(photo.area) }}</td>
																		</tr>
																	</template>
																	<template v-if="JSON.parse(photo.areas).length > 0">
																		<tr>
																			<td class="pb-1" colspan="2">
																				<p class="fw-bold mb-1">{{ lang.get('Metering areas') }}</p>
																				<div>
																					<table class="table table-sm table-borderless mb-0">
																						<tbody class="small">
																							<template
																								v-for="(area, index) in JSON.parse(photo.areas)"
																								:key="index"
																							>
																								<template v-if="area != null">
																									<tr>
																										<th
																												class="text-nowrap fw-bold text-end ps-0 py-1 w--1 pe-2"
																										>{{ area.ev }}</th>
																										<td
																												class="text-end pe-0 py-1 ps-2"
																										>{{ area.description }}</td>
																									</tr>
																								</template>
																							</template>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>
																	</template>
																	<template v-if="JSON.parse(photo.filters).length > 0">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Filters') }}</th>
																			<td class="text-end">
																				<template
																					v-for="(filter, index) in JSON.parse(photo.filters)"
																					:key="index"
																				>
																					<span class="d-block text-end">
																						<template
																							v-if="filter === 'gnd2'"
																						>{{ lang.get('Graduated ND2') }}</template>
																						<template
																							v-if="filter === 'gnd4'"
																						>{{ lang.get('Graduated ND4') }}</template>
																						<template
																							v-if="filter === 'gnd8'"
																						>{{ lang.get('Graduated ND8') }}</template>
																					</span>
																				</template>
																			</td>
																		</tr>
																	</template>
																	<template v-if="photo.extension">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Extension tubes') }}</th>
																			<td class="text-end">{{ photo.extension }} mm</td>
																		</tr>
																	</template>
																	<template v-if="photo.flash">
																		<tr>
																			<th class="fw-bold">{{ lang.get('Flash') }}</th>
																			<td class="text-end">{{ lang.get(photo.flash) }}</td>
																		</tr>
																	</template>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="card-footer text-end border-0 p-0 overflow-hidden">
										<ul class="nav nav-pills nav-justified card-header-pills m-0">
											<li class="nav-item">
												<button
													class="nav-link bg-secondary m-0 rounded-0 py-3 w-100"
													data-bs-target="#modal__options"
													data-bs-toggle="modal"
													v-on:click="openOptions(photo.id_photo)"
												>{{ lang.get('Options') }}</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</template>
					</div>
				</template>
				<template v-else>
					<AlertInfo
						:title="lang.get('No Photos')"
						:content="lang.key('lorem')"
					/>
				</template>
			</div>
		</div>
	</main>
	<div>
		<ModalOptions
			:delete_title="lang.get('Delete Photo?')"
			:delete_content="lang.key('lorem')"
		/>
	</div>
</template>

<script>

	import { defineComponent } from 'vue'
	
	import { useDataStore } from '@/stores/data'
	import { useEmulsionsDataStore } from '@/stores/emulsions_data'
	import { useCamerasDataStore } from '@/stores/cameras_data'
	import { useLensesDataStore } from '@/stores/lenses_data'
	import { useFilmsDataStore } from '@/stores/films_data'
	import { usePhotosDataStore } from '@/stores/photos_data'
	import { useOptionsDataStore } from '@/stores/options_data'
	import { useActiveDataStore } from '@/stores/active'
	import { useLangStore } from '@/stores/lang'
	
	import AlertInfo from "@/components/alerts/AlertInfo.vue"
	
	import ModalOptions from "@/components/modals/ModalOptions.vue"
	
	import moment from 'moment'
	
	export default defineComponent({
		name: 'ContentPhotos',
		components: {
			AlertInfo,
			ModalOptions
		},
		created: function () {
			this.moment = moment
		},
		setup: function () {
			const ds = useDataStore()
			const ds_emulsions = useEmulsionsDataStore()
			const ds_cameras = useCamerasDataStore()
			const ds_lenses = useLensesDataStore()
			const ds_films = useFilmsDataStore()
			const ds_photos = usePhotosDataStore()
			const ds_active = useActiveDataStore()
			const ds_options = useOptionsDataStore()
			const lang = useLangStore()
			
			function openOptions(id) {
				ds_options.params.db = 'photos'
				ds_options.params.id = id
				ds_options.params.action = 'init'
			}
			
			
			return {
				ds,
				ds_emulsions,
				ds_cameras,
				ds_lenses,
				ds_films,
				ds_photos,
				ds_active,
				openOptions,
				lang
			}
			
		}
	})
</script>