<template>
	<header class="h--90">
		<div class="bg-white shadow fixed-top">
			<div>
				<nav class="navbar navbar-light navbar-expand text-white bg-black py-0">
					<div class="container-fluid p-0">
						<div class="collapse navbar-collapse" id="navcol-1">
							<button 
								class="btn btn-primary btn-lg shadow-none me-auto p-0 rounded-0" 
								type="button"
								data-bs-target="#modal__menu"
								data-bs-toggle="modal"
							>
								<span class="ratio w--50 h--50">
									<span>
										<span>
											<IconHeaderMenu />
										</span>
									</span>
								</span>
							</button>
							<h1 class="text-uppercase text-center mx-auto mb-0 h5">
								<template v-if="route === 'photos'">
									<span class="d-block">{{ lang.get('Photos') }}</span>
									<template 
										v-if="
											ds_active.film.id != null
											&& ds_active.film.id !== ''
										"
									>
										<small 
											class="text-truncate text-white-50 d-block fs--small"
											style="max-width: calc(100vw - 200px);"
										>{{ ds_active.film.name }}</small>
									</template>
								</template>
								<template v-else-if="route === 'films'">
									<span class="d-block">{{ lang.get('Films') }}</span>
								</template>
								<template v-else-if="route === 'cameras'">
									<span class="d-block">{{ lang.get('Cameras') }}</span>
								</template>
								<template v-else-if="route === 'lenses'">
									<span class="d-block">{{ lang.get('Lenses') }}</span>
								</template>
								<template v-else-if="route === 'emulsions'">
									<span class="d-block">{{ lang.get('Emulsions') }}</span>
								</template>
								<template v-else>
									<span class="d-block">{{ lang.get('Filmnote') }}</span>
								</template>
							</h1>
							<button 
								class="btn btn-primary btn-lg shadow-none ms-auto p-0 rounded-0" 
								type="button" 
								data-bs-toggle="modal"
								:data-bs-target="target" 
								v-on:click="addClick()"
							>
								<span class="ratio w--50 h--50">
									<span>
										<span>
											<IconHeaderPlus />
										</span>
									</span>
								</span>
							</button>
							<template v-if="route === 'photos'">
								<button 
									class="btn btn-primary btn-lg shadow-none p-0 rounded-0 position-absolute ms--n100 start-100" 
									type="button"
									data-bs-target="#modal__films" 
									data-bs-toggle="modal"
								>
									<span class="ratio w--50 h--50">
										<span>
											<span>
												<img class="w--23 h--23" src="/assets/img/icons/icon-camera-roll.svg" alt="" />
											</span>
										</span>
									</span>
								</button>
							</template>
						</div>
					</div>
				</nav>
			</div>
			<div>
				<ul class="nav nav-tabs nav-justified text-center">
					<li class="nav-item">
						<router-link 
							class="nav-link fw-normal d-flex justify-content-center align-items-center w-100 p-0 h--40" 
							:class="(route === 'photos') ? 'active' : ''"
							to="/photos"
						>
							<small class="fw-bold">{{ lang.get('Photos') }}</small>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link 
							class="nav-link fw-normal d-flex justify-content-center align-items-center w-100 p-0 h--40" 
							:class="(route === 'films') ? 'active' : ''"
							to="/films"
						>
							<small class="fw-bold">{{ lang.get('Films') }}</small>
						</router-link>
					</li>
					<li class="nav-item">
						<button
							class="nav-link fw-normal d-flex justify-content-center align-items-center w-100 p-0 h--40" 
							:class="(route === 'emulsions' || route === 'cameras' || route === 'lenses') ? 'active' : ''"
							data-bs-target="#modal__nav" 
							data-bs-toggle="modal"
						>
							<template v-if="route === 'cameras'">
								<small class="fw-bold">{{ lang.get('Cameras') }}</small>
							</template>
							<template v-else-if="route === 'lenses'">
								<small class="fw-bold">{{ lang.get('Lenses') }}</small>
							</template>
							<template v-else-if="route === 'emulsions'">
								<small class="fw-bold">{{ lang.get('Emulsions') }}</small>
							</template>
							<template v-else>
								<small class="fw-bold">{{ lang.get('More') }}</small>
							</template>
							<i class="material-icons fs-4 me--n20 opacity-75 text-black-50">arrow_drop_down</i>
						</button>
					</li>
				</ul>
			</div>
		</div>
	</header>
</template>

<script>

	import { computed, ref, watch } from 'vue'
	import { useRoute } from 'vue-router'

	import IconHeaderMenu from "@/assets/icons/IconHeaderMenu.vue"
	import IconHeaderPlus from "@/assets/icons/IconHeaderPlus.vue"
	
	import { useActiveDataStore } from '@/stores/active'
	import { useOptionsDataStore } from '@/stores/options_data'
	
	import { useLangStore } from '@/stores/lang'
	
	export default {
		name: 'AppHeader',
		components: {
			IconHeaderMenu,
			IconHeaderPlus,
		},
		setup () {
			const ds_active = useActiveDataStore()
			const ds_options = useOptionsDataStore()
			
			const lang = useLangStore()
			
			
			const route = computed(() => {
				return useRoute().name
			})
			
			
			const target = ref('#modal__photo__add')
			const db = ref('photos')
			
			watch (useRoute(), (route) => {
				if (route.name === 'cameras') {
					target.value = '#modal__camera__add'
				}
				else if (route.name === 'lenses') {
					target.value = '#modal__lens__add'
				}
				else if (route.name === 'emulsions') {
					target.value = '#modal__emulsion__add'
				}
				else if (route.name === 'films') {
					target.value = '#modal__film__add'
				}
				else if (route.name === 'photos') {
					target.value = '#modal__photo__add'
				}
				
				db.value = route.name
			})
			
			
			function addClick() {
				ds_options.params.action = 'add'
				ds_options.params.db = db.value
			}
			
			return { 
				route,
				ds_active,
				target,
				addClick,
				lang
			}
		}
	}
</script>