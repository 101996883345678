import { defineStore } from 'pinia'

export const useOptionsDataStore = defineStore('options', {
	state: () => {
		return {
			params: {
				db: null,
				id: null,
				action: null,
			}
		}
	},
	actions: {
		reset() {
			this.params.db = null
			this.params.id = null
			this.params.action = null
		}
	}
})