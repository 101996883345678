<template>
	<div 
		class="modal fade" 
		role="dialog" 
		tabindex="-1" 
		id="modal__camera__add"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
		
	>
		<div 
			class="modal-dialog modal-dialog-centered modal-dialog-scrollable py-4" 
			role="document"
		>
			<div class="modal-content overflow-hidden">
				<div class="modal-body bg-secondary p-0">
					<div class="table-responsive bg-white">
						<table class="table table-striped mb-0">
							<tbody>
								<tr>
									<td>
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Name') }}</span>
											<IconLabelMandatory v-if="!data.name" />
											<IconLabelCheck v-else />
										</label>
										<input 
											class="form-control-lg form-control rounded-pill" 
											type="text" 
											:placeholder="lang.get('Camera name')"
											v-model="data.name"
										>
									</td>
								</tr>
								<tr>
									<td>
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Model') }}</span>
											<IconLabelMandatory v-if="!data.model" />
											<IconLabelCheck v-else />
										</label>
										<input 
											type="text" 
											class="form-control py--6 rounded-pill" 
											:placeholder="lang.get('Camera model')"
											v-model="data.model"
										>
									</td>
								</tr>
								<tr>
									<td>
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Type') }}</span>
											<IconLabelMandatory v-if="!data.type" />
											<IconLabelCheck v-else />
										</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.type"
										>
											<option value="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(type, index) in camera_store.type"
												:key="index"
											>
												<option 
													:value="type"
												>{{ lang.get(type) }}</option>
											</template>
										</select>
									</td>
								</tr>
								<tr>
									<td>
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Brand') }}</span>
											<IconLabelMandatory v-if="!data.brand" />
											<IconLabelCheck v-else />
										</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.brand"
										>
											<option value="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(brand, index) in camera_store.brand"
												:key="index"
											>
												<option 
													:value="brand"
												>{{ brand }}</option>
											</template>
										</select>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer p-0 bg-white border-0">
					<ul class="nav nav-pills nav-justified m-0 w-100">
						<template v-if="ds_options.params.action === 'add'">
							<li class="nav-item">
								<button 
									class="nav-link fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100" 
									v-on:click="addCamera()"
									:disabled="disabled"
									:class="(disabled === true) ? 'bg-secondary text-primary text-opacity-25' : 'bg-primary text-white'"
								>
									<IconBtnPlus />
									<span>{{ lang.get('Add Camera') }}</span>
								</button>
							</li>
						</template>
						<template v-if="ds_options.params.action === 'edit'">
							<li class="nav-item">
								<button 
									class="nav-link fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100"
									v-on:click="updateCamera()"
									:disabled="disabled"
									:class="(disabled === true) ? 'bg-secondary text-primary text-opacity-25' : 'bg-primary text-white'"
								>
									<IconBtnRefresh />
									<span>{{ lang.get('Save changes') }}</span>
								</button>
							</li>
						</template>
						<li class="nav-item">
							<button 
								class="nav-link bg-secondary text-primary text-opacity-50 fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100"
								data-bs-target="#modal__camera__add" 
								data-bs-toggle="modal"
								id="btn__modal_camera_add__close"
								v-on:click="closeForm()"
							>
								<IconBtnCloseSmall />
								<span>{{ lang.get('Close') }}</span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent, ref, watch } from 'vue'
	
	import IconBtnPlus from "@/assets/icons/IconBtnPlus.vue"
	import IconBtnCloseSmall from "@/assets/icons/IconBtnCloseSmall.vue"
	import IconBtnRefresh from "@/assets/icons/IconBtnRefresh.vue"
	import IconLabelMandatory from "@/assets/icons/IconLabelMandatory.vue"
	import IconLabelCheck from "@/assets/icons/IconLabelCheck.vue"
	
	import { useCameraStore } from '@/stores/cameras'
	import { useOptionsDataStore } from '@/stores/options_data'
	import { useActionDataStore } from '@/stores/action_data'
	import { useLangStore } from '@/stores/lang'
	
	import { db } from "@/databases/db";
	
	export default defineComponent({
		name: 'ModalCamerasAdd',
		components: {
			IconBtnPlus,
			IconBtnCloseSmall,
			IconBtnRefresh,
			IconLabelMandatory,
			IconLabelCheck
		},
		setup() {
			const camera_store = useCameraStore()
			const ds_options = useOptionsDataStore()
			const ds_action = useActionDataStore()
			const lang = useLangStore()
			
			const data = ref({})
			const disabled = ref(null)
			
			watch (ds_options, () => {
				// Add new
				if (ds_options.params.action === 'add') {
					clearForm()
				}
				
				// Edit element
				else if (ds_options.params.action === 'edit') {
					try {
						db.cameras
						.where('id_camera')
						.equals(ds_options.params.id)
						.first()
						.then((camera) => {
							data.value.name = camera.name
							data.value.model = camera.model
							data.value.type = camera.type
							data.value.brand = camera.brand
						})
					} catch (error) {
						console.log('Error!')
					}
				}
			})
			
			async function updateCamera() {
				try {
					await db.cameras.update(
						ds_options.params.id,
						{
							name: data.value.name,
							model: data.value.model,
							type: data.value.type,
							brand: data.value.brand,
						}
					).then(
						ds_action.updateProcess('cameras', 'update')
					)
				} catch (error) {
					console.log('Error!')
				}
				
				document.getElementById('btn__modal_camera_add__close').click();
			}
			
			
			watch (data.value, () => {
				disabled.value = (
					data.value.name == null || data.value.name === ''
					|| data.value.model == null || data.value.model === ''
					|| data.value.type == null || data.value.type === ''
					|| data.value.brand == null || data.value.brand === ''
				)
			})
			
			async function addCamera() {
				try {
					await db.cameras.add({
						name: data.value.name,
						model: data.value.model,
						type: data.value.type,
						brand: data.value.brand,
					}).then(
						ds_action.updateProcess('cameras', 'add')
					)
				} catch (error) {
					console.log('Error!')
				}
				
				document.getElementById('btn__modal_camera_add__close').click();
			}
			
			function clearForm() {
				data.value.name = null
				data.value.model = null
				data.value.type = ''
				data.value.brand = ''
				
				disabled.value = true
			}
			
			function closeForm() {
				clearForm()
				ds_options.reset()
			}
			
			
			return { 
				data, addCamera, disabled, camera_store,
				closeForm,
				ds_options,
				updateCamera,
				lang
			}
		}
	})
</script>