import { defineStore } from 'pinia'

import { useStorage } from '@vueuse/core'

export const useActiveDataStore = defineStore('active', {
	state: () => {
		return {
			film: useStorage('active_film', {
				id: null,
				name: null,
			})
		}
	}
})