import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import PhotosView from '../views/PhotosView.vue'
import FilmsView from '../views/FilmsView.vue'
import CamerasView from '../views/CamerasView.vue'
import LensesView from '../views/LensesView.vue'
import EmulsionsView from '../views/EmulsionsView.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/photos',
		name: 'photos',
		component: PhotosView
	},
	{
		path: '/films',
		name: 'films',
		component: FilmsView
	},
	{
		path: '/cameras',
		name: 'cameras',
		component: CamerasView
	},
	{
		path: '/lenses',
		name: 'lenses',
		component: LensesView
	},
	{
		path: '/emulsions',
		name: 'emulsions',
		component: EmulsionsView
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
