<template>
	<main>
		<div class="py-3">
			<div class="container-fluid">
				<template v-if="ds.count.cameras > 0">
					<div class="row gx-3 gy-3 row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-4">
						<template
							v-for="camera in ds_cameras.items"
							:key="camera.id_camera"
						>
							<div class="col">
								<div class="card">
									<div class="card-header text-white bg-primary pe-3">
										<div class="row align-items-center">
											<div class="col">
												<h5 class="text-truncate mb-0">{{ camera.name }}</h5>
												<p class="fw-bold text-white-50 mb-0 small line-height-none mt-1">{{ camera.brand }}</p>
											</div>
										</div>
									</div>
									<div class="card-body shadow-sm p-0 position-relative">
										<div class="table-responsive">
											<table class="table table-sm mb-0">
												<tbody class="small">
													<tr>
														<th class="fw-bold">{{ lang.get('Model') }}</th>
														<td class="text-end">{{ camera.model }}</td>
													</tr>
													<tr>
														<th class="fw-bold">{{ lang.get('Type') }}</th>
														<td class="text-end">{{ lang.get(camera.type) }}</td>
													</tr>
													<tr>
														<th class="fw-bold">{{ lang.get('Brand') }}</th>
														<td class="text-end">{{ camera.brand }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="card-footer text-end border-0 p-0 overflow-hidden">
										<ul class="nav nav-pills nav-justified card-header-pills m-0">
											<li class="nav-item">
												<button
													class="nav-link bg-secondary m-0 rounded-0 py-3 w-100"
													data-bs-target="#modal__options"
													data-bs-toggle="modal"
													v-on:click="openOptions(camera.id_camera)"
												>{{ lang.get('Options') }}</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</template>
					</div>
				</template>
				<template v-else>
					<AlertInfo 
						:title="lang.get('No Cameras')"
						:content="lang.key('lorem')"
					/>
				</template>
			</div>
		</div>
	</main>
	<div>
		<ModalOptions
			:delete_title="lang.get('Delete Camera?')"
			:delete_content="lang.key('lorem')"
		/>
	</div>
</template>

<script>
	import { defineComponent } from 'vue'
	
	import { useDataStore } from '@/stores/data'
	import { useCamerasDataStore } from '@/stores/cameras_data'
	import { useOptionsDataStore } from '@/stores/options_data'
	
	import AlertInfo from "@/components/alerts/AlertInfo.vue"
	
	import ModalOptions from "@/components/modals/ModalOptions.vue"
	
	import { useLangStore } from '@/stores/lang'
	
	export default defineComponent({
		name: 'ContentCameras',
		components: {
			AlertInfo,
			ModalOptions
		},
		setup() {
			const ds = useDataStore()
			const ds_cameras = useCamerasDataStore()
			const ds_options = useOptionsDataStore()
			const lang = useLangStore()
			
			function openOptions(id) {
				ds_options.params.db = 'cameras'
				ds_options.params.id = id
				ds_options.params.action = 'init'
			}
			
			return {
				ds,
				ds_cameras,
				openOptions,
				lang
			}
		}
	})
</script>