import { defineStore } from 'pinia'

import { useStorage } from '@vueuse/core'

export const useCreatedDataStore = defineStore('created', {
	state: () => {
		return {
			last: useStorage('created_last', {
				emulsion: {
					id: null,
					name: null,
				},
				camera: {
					id: null,
					name: null,
				},
				lens: {
					id: null,
					name: null,
				},
				film: {
					id: null,
					name: null,
				},
				photo: {
					id: null,
					name: null,
				},
			})
		}
	}
})