<template>
	<ContentEmulsions />
	<div>
		<ModalEmulsionsAdd />
	</div>
</template>

<script>
	import ContentEmulsions from "@/components/content/ContentEmulsions.vue"
	import ModalEmulsionsAdd from "@/components/modals/ModalEmulsionsAdd.vue"
	
	import { defineComponent } from 'vue'
	
	export default defineComponent({
		name: 'EmulsionsView',
		components: {
			ContentEmulsions,
			ModalEmulsionsAdd
		}
	})
</script>
