import { defineStore } from 'pinia'

export const usePhotoStore = defineStore('photos', {
	state: () => {
		return {
			mode: [
				'Manual',
				'Aperture Priority',
				'Time Priority',
			],
			aperture: [
				'f / 1.7',
				'f / 1.8',
				'f / 2',
				'f / 2.8',
				'f / 3.5',
				'f / 4',
				'f / 4.5',
				'f / 5.6',
				'f / 8',
				'f / 11',
				'f / 16',
				'f / 22',
			],
			shutter: [
				'30 s',
				'15 s',
				'8 s',
				'4 s',
				'2 s',
				'1 s',
				'1 / 2 s',
				'1 / 4 s',
				'1 / 8 s',
				'1 / 15 s',
				'1 / 30 s',
				'1 / 60 s',
				'1 / 125 s',
				'1 / 250 s',
				'1 / 500 s',
				'1 / 1000 s',
				'1 / 2000 s',
			],
			focal: [
				'24 mm',
				'28 mm',
				'30 mm',
				'35 mm',
				'40 mm',
				'45 mm',
				'50 mm',
				'55 mm',
				'60 mm',
				'65 mm',
				'70 mm',
				'75 mm',
				'80 mm',
				'85 mm',
				'90 mm',
				'95 mm',
				'100 mm',
				'105 mm',
			],
			ev: [
				'- 4 EV',
				'- 3 ½ EV',
				'- 3 EV',
				'- 2 ½ EV',
				'- 2 EV',
				'- 1 ½ EV',
				'- 1 EV',
				'- ½ EV',
				'0 EV',
				'+ ½ EV',
				'+ 1 EV',
				'+ 1 ½ EV',
				'+ 2 EV',
				'+ 2 ½ EV',
				'+ 3 EV',
				'+ 3 ½ EV',
				'+ 4 EV',
			],
			metering: [
				'Evaluative',
				'Spot',
				'Point',
				'Lightmeter',
			],
			tones: [
				'Lights',
				'Medium Tones',
				'Shadows',
			],
			filters: [
				'Graduated ND2',
				'Graduated ND4',
				'Graduated ND8',
			],
			flash: [
				'Yes',
				'No',
			],
		}
	}
})