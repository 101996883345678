import { defineStore } from 'pinia'

export const useEmulsionStore = defineStore('emulsions', {
	state: () => {
		return {
			type: [
				'Color',
				'Black and White'
			],
			iso: [
				25,
				50,
				100,
				160,
				200,
				250,
				400,
				500,
				800,
				1000,
				1600,
				3200
			],
			brand: [
				'Kodak',
				'Fuji',
				'Ilford',
				'Lomography',
				'Rollei',
				'Cinestill',
				'Foma',
				'Other',
			],
		}
	}
})