<template>
	<main>
		<div class="py-3">
			<div class="container-fluid">
				<template v-if="ds.count.films > 0">
					<div class="row gx-3 gy-3 row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-4">
						<template
							v-for="film in ds_films.items"
							:key="film.id_film"
						>
							<div class="col">
								<div class="card">
									<div class="card-header text-white bg-primary pe-3">
										<div class="row align-items-center">
											<div class="col">
												<h5 class="text-truncate mb-0">{{ film.name }}</h5>
												<template v-if="film.id_emulsion !== ''">
													<p class="fw-bold text-white-50 mb-0 small line-height-none mt-1">
														{{ ds_emulsions.items.find((emulsion) => {
															return emulsion.id_emulsion === film.id_emulsion
														}).name }}
													</p>
												</template>
											</div>
											<div
												class="col-auto"
												v-if="film.roll"
											>
												<span
													class="fs-2 fw-lighter text-white-50"
												>#{{ film.roll }}</span>
											</div>
										</div>
									</div>
									<div class="card-body shadow-sm p-0 position-relative">
										<div class="table-responsive">
											<table class="table table-sm mb-0">
												<tbody class="small">
													<tr>
														<th class="fw-bold">Exposures</th>
														<td class="text-end">36</td>
													</tr>
													<template v-if="film.id_emulsion !== ''">
														<tr>
															<th class="fw-bold">{{ lang.get('Emulsion') }}</th>
															<td class="text-end">
																{{ ds_emulsions.items.find((emulsion) => {
																	return emulsion.id_emulsion === film.id_emulsion
																}).name }}
															</td>
														</tr>
														<tr>
															<th class="fw-bold">{{ lang.get('Type') }}</th>
															<td
																class="text-end"
															>
																{{ lang.get(
																	ds_emulsions.items.find((emulsion) => {
																		return emulsion.id_emulsion === film.id_emulsion
																	}).type) }}
															</td>
														</tr>
														<tr>
															<th class="fw-bold">{{ lang.get('ISO') }}</th>
															<td
																class="text-end"
															>
																{{ ds_emulsions.items.find((emulsion) => {
																	return emulsion.id_emulsion === film.id_emulsion
																}).iso }}
															</td>
														</tr>
													</template>
													<template v-if="film.id_camera !== ''">
														<tr>
															<th class="fw-bold">{{ lang.get('Camera') }}</th>
															<td
																class="text-end"
															>
																{{ ds_cameras.items.find((camera) => {
																	return camera.id_camera === film.id_camera
																}).name }}
															</td>
														</tr>
													</template>
												</tbody>
											</table>
										</div>
										<div
											class="accordion accordion-flush"
											role="tablist"
											:id="`accordion-${film.id_film}`"
										>
											<div class="accordion-item">
												<h2 class="accordion-header" role="tab">
													<button
														class="accordion-button collapsed bg-light py-2"
														type="button"
														data-bs-toggle="collapse"
														:data-bs-target="`#accordion-${film.id_film} .item-1`"
													>
														<span class="fw-bold fs--small ft--body ms--n2">{{ lang.get('More') }}</span>
													</button>
												</h2>
												<div
													class="accordion-collapse collapse item-1"
													role="tabpanel"
												>
													<div class="accordion-body p-0">
														<div class="table-responsive">
															<table class="table table-sm mb-0">
																<tbody class="small">
																	<tr>
																		<th class="fw-bold">{{ lang.get('Expiration date') }}</th>
																		<td class="text-end">{{ film.date_expiration }}</td>
																	</tr>
																	<tr>
																		<th class="fw-bold">{{ lang.get('Starts on') }}</th>
																		<td class="text-end">{{ film.date_start }}</td>
																	</tr>
																	<tr>
																		<th class="fw-bold">{{ lang.get('Completed on') }}</th>
																		<td class="text-end">{{ film.date_completed }}</td>
																	</tr>
																	<tr>
																		<th class="fw-bold">{{ lang.get('Develop on') }}</th>
																		<td class="text-end">{{ film.date_develop }}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="card-footer text-end border-0 p-0 overflow-hidden">
										<ul class="nav nav-pills nav-justified card-header-pills m-0">
											<li class="nav-item">
												<router-link
													class="nav-link fw-bold bg-secondary m-0 rounded-0 py-3"
													to="/photos"
													v-on:click="activateFilm(film.id_film, film.name)"
												>{{ lang.get('Photos') }}</router-link>
											</li>
											<li class="nav-item">
												<button
													class="nav-link bg-secondary m-0 rounded-0 py-3 w-100"
													data-bs-toggle="modal"
													data-bs-target="#modal__options"
													v-on:click="openOptions(film.id_film)"
												>{{ lang.get('Options') }}</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</template>
					</div>
				</template>
				<template v-else>
					<AlertInfo
						:title="lang.get('No Films')"
						:content="lang.key('lorem')"
					/>
				</template>
			</div>
		</div>
	</main>
	<div>
		<ModalOptions
			:delete_title="lang.get('Delete Film?')"
			:delete_content="lang.key('lorem')"
		/>
	</div>
</template>

<script>
	import { defineComponent } from 'vue'
	
	import { useDataStore } from '@/stores/data'
	import { useEmulsionsDataStore } from '@/stores/emulsions_data'
	import { useCamerasDataStore } from '@/stores/cameras_data'
	import { useFilmsDataStore } from '@/stores/films_data'
	import { useOptionsDataStore } from '@/stores/options_data'
	import { useActiveDataStore } from '@/stores/active'
	import { useLangStore } from '@/stores/lang'
	
	import AlertInfo from "@/components/alerts/AlertInfo.vue"
	
	import ModalOptions from "@/components/modals/ModalOptions.vue"
	
	export default defineComponent({
		name: 'ContentFilms',
		components: {
			AlertInfo,
			ModalOptions
		},
		setup() {
			const ds = useDataStore()
			const ds_emulsions = useEmulsionsDataStore()
			const ds_cameras = useCamerasDataStore()
			const ds_films = useFilmsDataStore()
			const ds_active = useActiveDataStore()
			const ds_options = useOptionsDataStore()
			const lang = useLangStore()
			
			
			function openOptions(id) {
				ds_options.params.db = 'films'
				ds_options.params.id = id
				ds_options.params.action = 'init'
			}
			
			function activateFilm(id, name) {
				ds_active.film.id = id
				ds_active.film.name = name
			}
			
			
			
			return {
				ds,
				ds_emulsions,
				ds_cameras,
				ds_films,
				openOptions,
				activateFilm,
				lang
			}
		}
	})
</script>