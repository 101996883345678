import { defineStore } from 'pinia'

export const useLangStore = defineStore('lang', {
	state: () => {
		return {
			default: 'en',
			languages: {
				en: 'English',
				pl: 'Polski'
			},
			phrases: [
				{
					en: 'Filmnote',
					pl: 'Filmnote'
				},
				{
					en: 'Photos',
					pl: 'Zdjęcia'
				},
				{
					en: 'Films',
					pl: 'Filmy'
				},
				{
					en: 'Lenses',
					pl: 'Obiektywy'
				},
				{
					en: 'Cameras',
					pl: 'Aparaty'
				},
				{
					en: 'Emulsions',
					pl: 'Emulsje'
				},
				{
					en: 'More',
					pl: 'Więcej'
				},
				{
					en: 'Close',
					pl: 'Zamknij'
				},
				{
					en: 'Edit',
					pl: 'Edytuj'
				},
				{
					en: 'Delete',
					pl: 'Usuń'
				},
				{
					en: 'Cancel',
					pl: 'Anuluj'
				},
				{
					en: 'Language',
					pl: 'Język aplikacji'
				},
				{
					en: 'Options',
					pl: 'Opcje'
				},
				{
					en: 'No Emulsions',
					pl: 'Brak emulsji'
				},
				{
					en: 'No Cameras',
					pl: 'Brak aparatów'
				},
				{
					en: 'No Lenses',
					pl: 'Brak obiektywów'
				},
				{
					en: 'No Films',
					pl: 'Brak filmów'
				},
				{
					en: 'No Photos',
					pl: 'Brak zdjęć'
				},
				{
					en: 'Delete Emulsion?',
					pl: 'Usunąć emulsję?'
				},
				{
					en: 'Delete Camera?',
					pl: 'Usunąć aparat?'
				},
				{
					en: 'Delete Lens?',
					pl: 'Usunąć obiektyw?'
				},
				{
					en: 'Delete Film?',
					pl: 'Usunąć film?'
				},
				{
					en: 'Delete Photo?',
					pl: 'Usunąć zdjęcie?'
				},
				{
					key: 'lorem',
					en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar ipsum a lorem maximus efficitur at sit amet massa',
					pl: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar ipsum a lorem maximus efficitur at sit amet massa'
				},
				{
					en: 'Add Emulsion',
					pl: 'Dodaj emulsję'
				},
				{
					en: 'Add Camera',
					pl: 'Dodaj aparat'
				},{
					en: 'Add Lens',
					pl: 'Dodaj obiektyw'
				},
				{
					en: 'Add Film',
					pl: 'Dodaj film'
				},
				{
					en: 'Add Photo',
					pl: 'Dodaj zdjęcie'
				},
				{
					en: 'Save changes',
					pl: 'Zapisz zmiany'
				},
				{
					en: 'Select…',
					pl: 'Wybierz…'
				},
				{
					en: 'Name',
					pl: 'Nazwa'
				},
				{
					en: 'Type',
					pl: 'Rodzaj'
				},
				{
					en: 'ISO',
					pl: 'ISO'
				},
				{
					en: 'Brand',
					pl: 'Marka'
				},
				{
					en: 'Emulsion name',
					pl: 'Nazwa emulsji'
				},
				{
					en: 'Color',
					pl: 'Kolorowa'
				},
				{
					en: 'Black and White',
					pl: 'Czarno-biała'
				},
				{
					en: 'Camera name',
					pl: 'Nazwa aparatu'
				},
				{
					en: 'Camera model',
					pl: 'Model aparatu'
				},
				{
					en: '35 mm',
					pl: 'Małoobrazkowy'
				},
				{
					en: 'Medium Format',
					pl: 'Średnioformatowy'
				},
				{
					en: 'Lens name',
					pl: 'Nazwa obiektywu'
				},
				{
					en: 'Model',
					pl: 'Model'
				},
				{
					en: 'Lens model',
					pl: 'Model obiektywu'
				},
				{
					en: 'Film name',
					pl: 'Nazwa filmu'
				},
				{
					en: 'Roll',
					pl: 'Nr'
				},
				{
					en: 'Emulsion',
					pl: 'Emulsja'
				},
				{
					en: 'Camera',
					pl: 'Aparat'
				},
				{
					en: 'Expiration date',
					pl: 'Data ważności'
				},
				{
					en: 'Starts on',
					pl: 'Rozpoczęta dnia'
				},
				{
					en: 'Completed on',
					pl: 'Ukończona dnia'
				},
				{
					en: 'Develop on',
					pl: 'Wywołana dnia'
				},
				{
					en: 'Photo name',
					pl: 'Tytuł zdjęcia'
				},
				{
					en: 'Exp',
					pl: 'Nr'
				},
				{
					en: 'Taken',
					pl: 'Wykonane'
				},
				{
					en: 'Mode',
					pl: 'Tryb'
				},
				{
					en: 'Manual',
					pl: 'Manualny'
				},
				{
					en: 'Aperture Priority',
					pl: 'Priorytet przysłony'
				},
				{
					en: 'Time Priority',
					pl: 'Priorytet czasu'
				},
				{
					en: 'Aperture',
					pl: 'Przysłona'
				},
				{
					en: 'Shutter speed',
					pl: 'Czas naświetlania'
				},
				{
					en: 'Focal length',
					pl: 'Ogniskowa'
				},
				{
					en: 'Film',
					pl: 'Film'
				},
				{
					en: 'Lens',
					pl: 'Obiektyw'
				},
				{
					en: 'EV correction',
					pl: 'Korekta ekspozycji'
				},
				{
					en: 'Light metering',
					pl: 'Pomiar światła'
				},
				{
					en: 'Evaluative',
					pl: 'Ewaluacyjny'
				},
				{
					en: 'Spot',
					pl: 'Skupiony'
				},
				{
					en: 'Point',
					pl: 'Punktowy'
				},
				{
					en: 'Lightmeter',
					pl: 'Światłomierz'
				},
				{
					en: 'Metering on',
					pl: 'Pomiar na'
				},
				{
					en: 'Lights',
					pl: 'Światła'
				},
				{
					en: 'Medium Tones',
					pl: 'Średnie tony'
				},
				{
					en: 'Shadows',
					pl: 'Cienie'
				},
				{
					en: 'Metering areas',
					pl: 'Punkty pomiaru'
				},
				{
					en: 'Description',
					pl: 'Opis'
				},
				{
					en: 'Add',
					pl: 'Dodaj'
				},
				{
					en: 'Filters',
					pl: 'Filtry'
				},
				{
					en: 'Extension tubes',
					pl: 'Pierścienie pośrednie'
				},
				{
					en: 'Set in mm',
					pl: 'Wpisz w mm'
				},
				{
					en: 'Flash',
					pl: 'Lampa błyskowa'
				},
				{
					en: 'Yes',
					pl: 'Tak'
				},
				{
					en: 'No',
					pl: 'Nie'
				},
				{
					en: 'Graduated ND2',
					pl: 'Neutralny szary GND2'
				},
				{
					en: 'Graduated ND4',
					pl: 'Neutralny szary GND4'
				},
				{
					en: 'Graduated ND8',
					pl: 'Neutralny szary GND8'
				},
				{
					en: 'Show Photos without Film',
					pl: 'Zdjęcia bez przypisanego filmu'
				},
			]
		}
	},
	actions: {
		get(phrase) {
			return this.phrases.find((elem) => {
				return elem.en === phrase
			})[this.default]
		},
		key(key) {
			return this.phrases.find((elem) => {
				return elem.key === key
			})[this.default]
		}
	}
})