import { defineStore } from 'pinia'

export const useCameraStore = defineStore('cameras', {
	state: () => {
		return {
			type: [
				'35 mm',
				'Medium Format'
			],
			brand: [
				'Canon',
				'Nikon',
				'Olympus',
				'Leica',
				'Other',
			],
		}
	}
})