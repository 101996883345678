<template>
	<div 
		class="bg-primary text-white d-flex justify-content-center align-items-center"
		style="width: 100vw; height: 100vh;"
	>
		<span class="spinner-border text-white" role="status"></span>
	</div>
</template>

<script>
	import { defineComponent } from 'vue'
	
	import router from '../router'
	
	export default defineComponent({
		name: 'HomeView',
		setup() {
			router.push('/photos')
		}
	})
</script>
