import { defineStore } from 'pinia'

export const useActionDataStore = defineStore('action', {
	state: () => {
		return {
			component: null,
			process: null,
			timestamp: null
		}
	},
	actions: {
		updateProcess(component, process) {
			this.component = component
			this.process = process
			this.timestamp = new Date().valueOf()
		}
	}
})