import { defineStore } from 'pinia'

export const useDataStore = defineStore('data', {
	state: () => {
		return {
			count: {
				emulsions: null,
				cameras: null,
				lenses: null,
				films: null,
				photos: null,
			}
		}
	}
})