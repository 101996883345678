<template>
	<div 
		class="modal fade" 
		role="dialog" 
		tabindex="-1" 
		id="modal__options"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
	>
		<div class="modal-dialog modal-sm modal-dialog-centered" role="document">
			<div class="modal-content overflow-hidden">
				<template v-if="ds_options.params.action === 'init'">
					<div class="modal-body shadow-sm p-0">
						<div class="overflow-hidden">
							<div class="list-group rounded-0 m--n1">
								<button 
									class="list-group-item list-group-item-action d-flex justify-content-center py-3"
									:data-bs-target="target" 
									data-bs-toggle="modal"
									v-on:click="editInit()"
								>
									<span class="text-uppercase fs-6 fw-normal">{{ lang.get('Edit') }}</span>
								</button>
								<button 
									class="list-group-item list-group-item-action d-flex justify-content-center py-3"
									v-on:click="deleteInit()"
								>
									<span class="text-uppercase fs-6 fw-normal">{{ lang.get('Delete') }}</span>
								</button>
							</div>
						</div>
					</div>
					<div class="modal-footer p-0 border-0">
						<ul class="nav nav-pills nav-justified m-0 w-100">
							<li class="nav-item">
								<button 
									class="nav-link bg-secondary m-0 rounded-0 py-3 w-100" 
									data-bs-target="#modal__options" 
									data-bs-toggle="modal"
									v-on:click="closeOptions()"
									id="btn__modal_options__close"
								>{{ lang.get('Close') }}</button>
							</li>
						</ul>
					</div>
				</template>
				<template v-if="ds_options.params.action === 'delete'">
					<div class="modal-body p-0">
						<div class="overflow-hidden p-4">
							<h2>{{ delete_title }}</h2>
							<p>{{ delete_content }}</p>
							<div>
								<div class="row gx-2">
									<div class="col-auto">
										<button 
											class="btn btn-primary btn-sm shadow-none rounded-pill" 
											type="button"
											v-on:click="deleteConfirm()"
										>{{ lang.get('Delete') }}</button>
									</div>
									<div class="col-auto">
										<button 
											class="btn btn-secondary btn-sm fw-bold shadow-none rounded-pill" 
											type="button"
											data-bs-target="#modal__options" 
											data-bs-toggle="modal"
											v-on:click="closeOptions()"
											id="btn__modal_options__close"
										>{{ lang.get('Cancel') }}</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent, ref, watch } from 'vue'
	
	import { db } from "@/databases/db"
	
	import { useOptionsDataStore } from '@/stores/options_data'
	import { useActionDataStore } from '@/stores/action_data'
	
	import { useLangStore } from '@/stores/lang'
	
	export default defineComponent({
		name: 'ModalOptions',
		props: [
			'delete_title',
			'delete_content',
		],
		setup() {
			const ds_options = useOptionsDataStore()
			const ds_action = useActionDataStore()
			
			const lang = useLangStore()
			
			function editInit() {
				ds_options.params.action = 'edit'
			}
			
			function deleteInit() {
				ds_options.params.action = 'delete'
			}
			
			function closeOptions() {
				ds_options.reset()
			}
			
			
			async function deleteConfirm() {
				
				try {
					if (ds_options.params.db === 'emulsions') {
						await db.films.where('id_emulsion').equals(ds_options.params.id).modify({id_emulsion: ''})
					} 
					
					else if (ds_options.params.db === 'cameras') {
						await db.films.where('id_camera').equals(ds_options.params.id).modify({id_camera: ''})
						await db.photos.where('id_camera').equals(ds_options.params.id).modify({id_camera: ''})
					} 
					
					else if (ds_options.params.db === 'lenses') {
						await db.photos.where('id_lens').equals(ds_options.params.id).modify({id_lens: ''})
					} 
					
					else if (ds_options.params.db === 'films') {
						await db.photos.where('id_film').equals(ds_options.params.id).modify({id_film: ''})
					}
					
					await db[ds_options.params.db]
						.delete(ds_options.params.id)
						.then(
							ds_action.updateProcess(ds_options.params.db, 'delete')
						)
					
				} catch (error) {
					console.log('Error!')
				}
				
				document.getElementById('btn__modal_options__close').click();
			}
			
			
			const target = ref()
			
			watch (ds_options, () => {
				if (ds_options.params.db === 'cameras') {
					target.value = '#modal__camera__add'
				}
				else if (ds_options.params.db === 'lenses') {
					target.value = '#modal__lens__add'
				}
				else if (ds_options.params.db === 'emulsions') {
					target.value = '#modal__emulsion__add'
				}
				else if (ds_options.params.db === 'films') {
					target.value = '#modal__film__add'
				}
				else if (ds_options.params.db === 'photos') {
					target.value = '#modal__photo__add'
				}
			})
			
			
			return {
				ds_options,
				editInit,
				deleteInit, deleteConfirm,
				closeOptions,
				target,
				lang
			}
			
		}
	})
</script>