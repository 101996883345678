<template>
	<div class="position-fixed translate-middle start-50 top-50 w-100 mt--45">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-10 col-sm-8 col-md-6 col-xl-5 col-xxl-4">
					<div 
						class="alert rounded-0 p-0 text-center text-primary text-opacity-25" 
						role="alert"
					>
						<h1>{{ title }}</h1>
						<p class="mb-0">{{ content }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	
	export default defineComponent({
		name: 'AlertInfo',
		props: [
			'title',
			'content'
		]
	})
</script>