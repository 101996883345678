<template>
	<ContentFilms />
	<div>
		<ModalFilmsAdd />
	</div>
</template>

<script>
	import ContentFilms from "@/components/content/ContentFilms.vue"
	import ModalFilmsAdd from "@/components/modals/ModalFilmsAdd.vue"

	import { defineComponent } from 'vue'
	
	export default defineComponent({
		name: 'FilmsView',
		components: {
			ContentFilms,
			ModalFilmsAdd,
		}
	})
</script>
