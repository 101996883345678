<template>
	<template v-if="route !== 'home'">
		<AppHeader />
	</template>
	<router-view/>
	<template v-if="route !== 'home'">
		<div>
			<ModalMenu />
			<ModalNav />
		</div>
	</template>
</template>

<script>
	import { defineComponent, watch } from 'vue'
	
	import AppHeader from "@/components/header/AppHeader.vue"
	import ModalMenu from "@/components/modals/ModalMenu.vue"
	import ModalNav from "@/components/modals/ModalNav.vue"
	
	import { db } from "./databases/db.js"
	
	import { useRoute } from 'vue-router'
	import { computed } from 'vue'
	
	import { useDataStore } from '@/stores/data'
	
	import { useEmulsionsDataStore } from '@/stores/emulsions_data'
	import { useCamerasDataStore } from '@/stores/cameras_data'
	import { useLensesDataStore } from '@/stores/lenses_data'
	import { useFilmsDataStore } from '@/stores/films_data'
	import { usePhotosDataStore } from '@/stores/photos_data'
	import { useCreatedDataStore } from '@/stores/created'
	import { useActiveDataStore } from '@/stores/active'
	import { useActionDataStore } from '@/stores/action_data'
	
	import { useLangStore } from '@/stores/lang'
	
	export default defineComponent({
		name: 'App',
		components: {
			AppHeader,
			ModalMenu,
			ModalNav
		},
		setup () {
			const ds = useDataStore()
			const ds_emulsions = useEmulsionsDataStore()
			const ds_cameras = useCamerasDataStore()
			const ds_lenses = useLensesDataStore()
			const ds_films = useFilmsDataStore()
			const ds_photos = usePhotosDataStore()
			const ds_created = useCreatedDataStore()
			const ds_active = useActiveDataStore()
			const ds_action = useActionDataStore()
			
			const lang = useLangStore()
			if (localStorage.getItem("lang") !== null) {
				lang.default = localStorage.getItem('lang')
			}
			
			// Emulsion: DB to Pinia
			
			loadEmulsions()
			
			watch (
				ds_action, () => {
					if (ds_action.component === 'emulsions') {
						loadEmulsions()
					}
				}
			)
			
			async function loadEmulsions() {
				try {
					await db.emulsions
						.toArray()
						.then((items) => {
							updateEmulsionStore(items)
						})
				} catch (error) {
					console.log('Error!')
				}
			}
			
			// Emulsions: update
			
			function updateEmulsionStore(items) {
				ds.count.emulsions = parseInt(items.length)
				ds_emulsions.items = items
				
				if (items.length > 0) {
					const last = items.slice(-1)[0]
					ds_created.last.emulsion.id = last.id_emulsion
					ds_created.last.emulsion.name = last.name
				} else {
					ds_created.last.emulsion.id = null
					ds_created.last.emulsion.name = null
				}
			}
			
			
			
			// Cameras: DB to Pinia
			
			loadCameras()
			
			watch (
				ds_action, () => {
					if (ds_action.component === 'cameras') {
						loadCameras()
					}
				}
			)
			
			async function loadCameras() {
				try {
					await db.cameras
						.toArray()
						.then((items) => {
							updateCameraStore(items)
						})
				} catch (error) {
					console.log('Error!')
				}
			}
			
			// Cameras: update
			
			function updateCameraStore(items) {
				ds.count.cameras = parseInt(items.length)
				ds_cameras.items = items
				
				if (items.length > 0) {
					const last = items.slice(-1)[0]
					ds_created.last.camera.id = last.id_camera
					ds_created.last.camera.name = last.name
				} else {
					ds_created.last.camera.id = null
					ds_created.last.camera.name = null
				}
			}
			
			
			
			
			// Lenses: DB to Pinia
			
			loadLenses()
			
			watch (
				ds_action, () => {
					if (ds_action.component === 'lenses') {
						loadLenses()
					}
				}
			)
			
			async function loadLenses() {
				try {
					await db.lenses
						.toArray()
						.then((items) => {
							updateLensStore(items)
						})
				} catch (error) {
					console.log('Error!')
				}
			}
			
			
			// Lenses: update
			
			function updateLensStore(items) {
				ds.count.lenses = parseInt(items.length)
				ds_lenses.items = items
				
				if (items.length > 0) {
					const last = items.slice(-1)[0]
					ds_created.last.lens.id = last.id_lens
					ds_created.last.lens.name = last.name
				} else {
					ds_created.last.lens.id = null
					ds_created.last.lens.name = null
				}
			}
			
			
			
			// Films: DB to Pinia
			
			loadFilms()
			
			watch (
				ds_action, () => {
					if (ds_action.component === 'films') {
						loadFilms()
					}
				}
			)
			
			async function loadFilms() {
				try {
					await db.films
						.toArray()
						.then((items) => {
							updateFilmStore(items)
						})
				} catch (error) {
					console.log('Error!')
				}
			}
			
			
			// Films: update
			
			function updateFilmStore(items) {
				ds.count.films = parseInt(items.length)
				ds_films.items = items
				
				if (items.length > 0) {
					
					const last = items.slice(-1)[0]
					ds_created.last.film.id = last.id_film
					ds_created.last.film.name = last.name
					
					/*
						if (ds_active.film.id == null) {
							ds_active.film.id = last.value.id_film
							ds_active.film.name = last.value.id_film
						}
						else if (ds_active.film.id === '') {
							ds_active.film.id = ''
							ds_active.film.name = ''
						}
						else if (ds_active.film.id > 0) {
							if (!ds_films.items[ds_active.film.id]) {
								ds_active.film.id = ''
								ds_active.film.name = ''
							}
						}
					*/
					
				} else {
					
					ds_created.last.film.id = null
					ds_created.last.film.name = null
					
					/*
						if (ds_active.film.id == null) {
							ds_active.film.id = ''
							ds_active.film.name = ''
						}
						else if (ds_active.film.id === '') {
							ds_active.film.id = ''
							ds_active.film.name = ''
						}
						else if (ds_active.film.id > 0) {
							ds_active.film.id = ''
							ds_active.film.name = ''
						}
					*/
					
				}
			}
			
			
			
			// Photos: DB to Pinia
			
			loadPhotos()
			
			watch (
				ds_action, () => {
					if (ds_action.component === 'photos') {
						loadPhotos()
					}
				}
			)
			
			watch (
				ds_action, () => {
					if (
						ds_action.component === 'films'
						&& ds_action.process === 'change'
					) {
						loadPhotos()
					}
				}
			)
			
			
			async function loadPhotos() {
				try {
					await db.photos
						.where('id_film')
						.equals(ds_active.film.id)
						.toArray()
						.then((items) => {
							updatePhotoStore(items)
						})
				} catch (error) {
					console.log('Error!')
				}
			}
			
			
			// Photos: update
			
			function updatePhotoStore(items) {
				ds.count.photos = parseInt(items.length)
				ds_photos.items = items
				
				if (items.length > 0) {
					const last = items.slice(-1)[0]
					ds_created.last.photo.id = last.id_photo
					ds_created.last.photo.name = last.name
				} else {
					ds_created.last.photo.id = null
					ds_created.last.photo.name = null
				}
			}
	
			
	
			// Return route
			
			const route = computed(() => {
				return useRoute().name
			})
			
			
			return { route }
			
		}
	})
</script>