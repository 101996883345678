<template>
	<div 
		class="modal fade" 
		role="dialog" 
		tabindex="-1" 
		id="modal__nav"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
	>
		<div 
			class="modal-dialog modal-dialog-centered" 
			role="document"
		>
			<div class="modal-content overflow-hidden">
				<div class="modal-body shadow-sm p-0">
					<div class="overflow-hidden">
						<div class="list-group rounded-0 m--n1">
							<button 
								class="list-group-item list-group-item-action d-flex justify-content-center py-3" 
								v-on:click="changePage('cameras')"
							>
								<span class="text-uppercase fs-6 fw-normal">{{ lang.get('Cameras') }}</span>
							</button>
							<button
								class="list-group-item list-group-item-action d-flex justify-content-center py-3" 
								v-on:click="changePage('lenses')"
							>
								<span class="text-uppercase fs-6 fw-normal">{{ lang.get('Lenses') }}</span>
							</button>
							<button 
								class="list-group-item list-group-item-action d-flex justify-content-center py-3" 
								v-on:click="changePage('emulsions')"
							>
								<span class="text-uppercase fs-6 fw-normal">{{ lang.get('Emulsions') }}</span>
							</button>
						</div>
					</div>
				</div>
				<div class="modal-footer p-0">
					<ul class="nav nav-pills nav-justified m-0 w-100">
						<li class="nav-item">
							<button 
								class="nav-link bg-secondary m-0 rounded-0 py-3 w-100" 
								data-bs-target="#modal__nav" 
								data-bs-toggle="modal"
								id="btn__nav__close"
							>{{ lang.get('Close') }}</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import router from '../../router'
	import { useLangStore } from '@/stores/lang'
	
	export default {
		name: 'ModalMenu',
		setup() {
			
			const lang = useLangStore()

			function changePage(slug) {
				closeMenu()

				const menuModal = document.getElementById('modal__nav')
				menuModal.addEventListener('hidden.bs.modal', function() {
					if (slug !== '') {
						router.push('/' + slug)
					}
					slug = ''
				})
			}
			
			function closeMenu() {
				document.getElementById('btn__nav__close').click()
			}
			
			return {
				changePage,
				lang
			}
			
		}
	}
</script>
