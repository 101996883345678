<template>
	<div
		id="modal__menu"
		class="modal fade"
		role="dialog"
		tabindex="-1"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
	>
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content overflow-hidden">
				<div class="modal-body p-0">
					<div>
						<div class="table-responsive m--n1">
							<table class="table mb-0 align-middle">
								<tbody>
									<tr>
										<th class="fw-bold pe-2 w-50">{{ lang.get('Language') }}</th>
										<td class="text-end ps-2 w-50">
											<select
												class="form-select rounded-pill text-center"
												v-model="lang.default"
												v-on:change="changeLang()"
											>
												<template
													v-for="(item, index) in lang.languages"
													:key="index"
												>
													<option
														:value="index"
													>{{ item }}</option>
												</template>
											</select>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="modal-footer p-0">
					<ul class="nav nav-pills nav-justified m-0 w-100">
						<li class="nav-item">
							<button
								class="nav-link bg-secondary m-0 rounded-0 py-3 w-100"
								data-bs-target="#modal__menu"
								data-bs-toggle="modal"
							>{{ lang.get('Close') }}</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useLangStore } from '@/stores/lang'
	
	export default {
		name: 'ModalMenu',
		setup() {
			const lang = useLangStore()
			
			function changeLang() {
				localStorage.setItem('lang', lang.default)
			}
			
			return {
				lang,
				changeLang
			}
		}
	}
</script>