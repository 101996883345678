<template>
	<div 
		class="modal fade" 
		role="dialog" 
		tabindex="-1" 
		id="modal__photo__add"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
	>
		<div 
			class="modal-dialog modal-dialog-centered modal-dialog-scrollable py-4" 
			role="document"
		>
			<div class="modal-content overflow-hidden">
				<div class="modal-body bg-secondary p-0">
					<div class="table-responsive bg-white">
						<table class="table table-striped mb-0">
							<tbody>
								<tr>
									<td class="pe-2">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Name') }}</span>
											<IconLabelMandatory v-if="!data.name" />
											<IconLabelCheck v-else />
										</label>
										<input 
											class="form-control-lg form-control rounded-pill" 
											type="text" 
											:placeholder="lang.get('Photo name')"
											v-model="data.name"
										>
									</td>
									<td class="ps-2 w--90">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Exp') }}</span>
											<IconLabelMandatory v-if="!data.frame" />
											<IconLabelCheck v-else />
										</label>
										<input 
											class="bg-white form-control-lg form-control text-center px-2 py--1 fs--large fw--300" 
											type="text" 
											pattern="\d*"
											placeholder="#"
											v-model="data.frame"
										>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Taken') }}</span>
											<IconLabelMandatory v-if="!data.taken" />
											<IconLabelCheck v-else />
										</label>
										<input 
											class="py--6 text-start form-control rounded-pill" 
											type="datetime-local" 
											placeholder="Date taken"
											v-model="data.taken"
										>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Mode') }}</span>
											<IconLabelMandatory v-if="!data.mode" />
											<IconLabelCheck v-else />
										</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.mode"
										>
											<option value="" selected="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(mode, index) in photo_store.mode"
												:key="index"
											>
												<option 
													:value="mode"
												>{{ lang.get(mode) }}</option>
											</template>
										</select>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Aperture') }}</span>
											<IconLabelMandatory v-if="!data.aperture" />
											<IconLabelCheck v-else />
										</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.aperture"
										>
											<option value="" selected="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(aperture, index) in photo_store.aperture"
												:key="index"
											>
												<option 
													:value="aperture"
												>{{ aperture }}</option>
											</template>
										</select>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Shutter speed') }}</span>
											<IconLabelMandatory v-if="!data.shutter" />
											<IconLabelCheck v-else />
										</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.shutter"
										>
											<option value="" selected="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(shutter, index) in photo_store.shutter"
												:key="index"
											>
												<option 
													:value="shutter"
												>{{ shutter }}</option>
											</template>
										</select>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-flex justify-content-between align-items-center">
											<span>{{ lang.get('Focal length') }}</span>
											<IconLabelMandatory v-if="!data.focal" />
											<IconLabelCheck v-else />
										</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.focal"
										>
											<option value="" selected="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(focal, index) in photo_store.focal"
												:key="index"
											>
												<option 
													:value="focal"
												>{{ focal }}</option>
											</template>
										</select>
									</td>
								</tr>
								<template v-if="ds.count.films">
									<tr>
										<td colspan="2">
											<label class="form-label fw-bold d-block">{{ lang.get('Film') }}</label>
											<select 
												class="form-select rounded-pill"
												v-model="data.id_film"
											>
												<option value="" selected="">{{ lang.get('Select…') }}</option>
												<template 
													v-for="(film, index) in ds_films.items"
													:key="index"
												>
													<template v-if="film != null">
														<option 
															:value="film.id_film"
														>{{ film.name }}</option>
													</template>
												</template>
											</select>
										</td>
									</tr>
								</template>
								<template v-if="ds.count.cameras">
									<tr>
										<td colspan="2">
											<label class="form-label fw-bold d-block">{{ lang.get('Camera') }}</label>
											<select 
												class="form-select rounded-pill"
												v-model="data.id_camera"
											>
												<option value="" selected="">{{ lang.get('Select…') }}</option>
												<template 
													v-for="(camera, index) in ds_cameras.items"
													:key="index"
												>
													<template v-if="camera != null">
														<option 
															:value="camera.id_camera"
														>{{ camera.name }}</option>
													</template>
												</template>
											</select>
										</td>
									</tr>
								</template>
								<template v-if="ds.count.lenses">
									<tr>
										<td colspan="2">
											<label class="form-label fw-bold d-block">{{ lang.get('Lens') }}</label>
											<select 
												class="form-select rounded-pill"
												v-model="data.id_lens"
											>
												<option value="" selected="">{{ lang.get('Select…') }}</option>
												<template 
													v-for="(lens, index) in ds_lenses.items"
													:key="index"
												>
													<template v-if="lens != null">
														<option 
															:value="lens.id_lens"
														>{{ lens.name }}</option>
													</template>
												</template>
											</select>
										</td>
									</tr>
								</template>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('EV correction') }}</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.ev"
										>
											<template 
												v-for="(ev, index) in photo_store.ev"
												:key="index"
											>
												<option 
													:value="ev"
												>{{ ev }}</option>
											</template>
										</select>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Light metering') }}</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.metering"
										>
											<option value="" selected="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(metering, index) in photo_store.metering"
												:key="index"
											>
												<option 
													:value="metering"
												>{{ lang.get(metering) }}</option>
											</template>
										</select>
									</td>
								</tr>
								<template v-if="data.metering === 'Lightmeter'">
									<tr>
										<td colspan="2">
											<label class="form-label fw-bold d-block">{{ lang.get('Metering on') }}</label>
											<select 
												class="form-select rounded-pill"
												v-model="data.area"
											>
												<option value="" selected="">{{ lang.get('Select…') }}</option>
												<template 
													v-for="(tones, index) in photo_store.tones"
													:key="index"
												>
													<option 
														:value="tones"
													>{{ lang.get(tones) }}</option>
												</template>
											</select>
										</td>
									</tr>
								</template>
								<template 
									v-if="
										data.metering === 'Spot'
										|| data.metering === 'Point'
									"
								>
									<tr>
										<td colspan="2">
											<label class="form-label fw-bold d-block">{{ lang.get('Metering areas') }}</label>
											<div class="mb-2 overflow-hidden">
												<table class="table table-sm mb--n1">
													<tbody class="small">
														<template
															v-for="(area, index) in data.areas"
															:key="index"
														>
															<template v-if="area">
																<tr>
																	<th class="text-nowrap fw-bold text-end ps-0 py-1 w--1 pe-2">{{ area.ev }}</th>
																	<td class="text-end pe-0 py-1 ps-2">{{ area.description }}</td>
																	<td class="text-end pe-0 py-1 ps-2 w--1">
																		<button 
																			class="btn shadow-none rounded-0 p-0 mb--2 mt--n2"
																			type="button"
																			v-on:click="removeArea(index)"
																		>
																			<IconBtnRemove />
																		</button>
																	</td>
																</tr>
															</template>
														</template>
													</tbody>
												</table>
											</div>
											<div class="row gx-2 gy-2">
												<div class="col-12">
													<textarea 
														class="form-control-sm form-control" 
														rows="3"
														:placeholder="lang.get('Description')"
														v-model="area.description"
													></textarea>
												</div>
												<div class="col">
													<select 
														class="form-select-sm form-select rounded-pill"
														v-model="area.ev"
													>
														<template 
															v-for="(ev, index) in photo_store.ev"
															:key="index"
														>
															<option 
																:value="ev"
															>{{ ev }}</option>
														</template>
													</select>
												</div>
												<div class="col-auto">
													<button 
														class="btn btn-primary btn-sm d-flex justify-content-center align-items-center w-100 rounded-pill" 
														type="button"
														:disabled="area.description === ''"
														v-on:click="addArea()"
													>
														<IconBtnPlus />
														<span>{{ lang.get('Add') }}</span>
													</button>
												</div>
											</div>
										</td>
									</tr>
								</template>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Filters') }}</label>
										<div class="row gy-2 row-cols-1">
											<div class="col">
												<div class="form-check form-switch">
													<input 
														class="form-check-input" 
														type="checkbox" 
														id="formCheck-1"
														v-model="data.filters"
														value="gnd2"
													>
													<label class="form-check-label" for="formCheck-1">{{ lang.get('Graduated ND2') }}</label>
												</div>
											</div>
											<div class="col">
												<div class="form-check form-switch">
													<input 
														class="form-check-input" 
														type="checkbox" 
														id="formCheck-2"
														v-model="data.filters"
														value="gnd4"
													>
													<label class="form-check-label" for="formCheck-2">{{ lang.get('Graduated ND4') }}</label>
												</div>
											</div>
											<div class="col">
												<div class="form-check form-switch">
													<input 
														class="form-check-input" 
														type="checkbox" 
														id="formCheck-3"
														v-model="data.filters"
														value="gnd8"
													>
													<label class="form-check-label" for="formCheck-3">{{ lang.get('Graduated ND8') }}</label>
												</div>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Extension tubes') }}</label>
										<input 
											type="text"
											pattern="\d*"
											class="form-control py--6 rounded-pill"
											:placeholder="lang.get('Set in mm')"
											v-model="data.extension"
										>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<label class="form-label fw-bold d-block">{{ lang.get('Flash') }}</label>
										<select 
											class="form-select rounded-pill"
											v-model="data.flash"
										>
											<option value="" selected="">{{ lang.get('Select…') }}</option>
											<template 
												v-for="(flash, index) in photo_store.flash"
												:key="index"
											>
												<option 
													:value="flash"
												>{{ lang.get(flash) }}</option>
											</template>
										</select>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer p-0 bg-white border-0">
					<ul class="nav nav-pills nav-justified m-0 w-100">
						<template v-if="ds_options.params.action === 'add'">
							<li class="nav-item">
								<button 
									class="nav-link fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100" 
									v-on:click="addPhoto()"
									:disabled="disabled"
									:class="(disabled === true) ? 'bg-secondary text-primary text-opacity-25' : 'bg-primary text-white'"
								>
									<IconBtnPlus />
									<span>{{ lang.get('Add Photo') }}</span>
								</button>
							</li>
						</template>
						<template v-if="ds_options.params.action === 'edit'">
							<li class="nav-item">
								<button 
									class="nav-link fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100"
									v-on:click="updatePhoto()"
									:disabled="disabled"
									:class="(disabled === true) ? 'bg-secondary text-primary text-opacity-25' : 'bg-primary text-white'"
								>
									<IconBtnRefresh />
									<span>{{ lang.get('Save changes') }}</span>
								</button>
							</li>
						</template>
						<li class="nav-item">
							<button 
								class="nav-link bg-secondary text-primary text-opacity-50 fw-bold d-flex justify-content-center align-items-center m-0 rounded-0 py-3 px-2 w-100"
								data-bs-target="#modal__photo__add" 
								data-bs-toggle="modal"
								id="btn__modal_photo_add__close"
								v-on:click="closeForm()"
							>
								<IconBtnCloseSmall />
								<span>{{ lang.get('Close') }}</span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconBtnPlus from "@/assets/icons/IconBtnPlus.vue"
	import IconBtnCloseSmall from "@/assets/icons/IconBtnCloseSmall.vue"
	import IconBtnRefresh from "@/assets/icons/IconBtnRefresh.vue"
	import IconLabelMandatory from "@/assets/icons/IconLabelMandatory.vue"
	import IconLabelCheck from "@/assets/icons/IconLabelCheck.vue"
	import IconBtnRemove from "@/assets/icons/IconBtnRemove.vue"
	
	import { defineComponent, ref, watch } from 'vue'
	
	import { db } from "@/databases/db"
	
	import moment from 'moment'
	
	import { useDataStore } from '@/stores/data'
	import { useCamerasDataStore } from '@/stores/cameras_data'
	import { useLensesDataStore } from '@/stores/lenses_data'
	import { useFilmsDataStore } from '@/stores/films_data'
	import { useActiveDataStore } from '@/stores/active'
	import { usePhotoStore } from '@/stores/photos'
	import { useOptionsDataStore } from '@/stores/options_data'
	import { useActionDataStore } from '@/stores/action_data'
	import { useLangStore } from '@/stores/lang'
	
	export default defineComponent({
		name: 'ModalPhotosAdd',
		components: {
			IconBtnPlus,
			IconBtnCloseSmall,
			IconBtnRefresh,
			IconLabelMandatory,
			IconLabelCheck,
			IconBtnRemove
		},
		setup() {
			const ds = useDataStore()
			const ds_cameras = useCamerasDataStore()
			const ds_lenses = useLensesDataStore()
			const ds_films = useFilmsDataStore()
			const ds_active = useActiveDataStore()
			const photo_store = usePhotoStore()
			const ds_options = useOptionsDataStore()
			const ds_action = useActionDataStore()
			const lang = useLangStore()
			
			const data = ref({})
			const disabled = ref(null)
			
			watch (ds_options, () => {
				// Add new
				if (ds_options.params.action === 'add') {
					clearForm()
				}
				
				// Edit element
				else if (ds_options.params.action === 'edit') {
					try {
						db.photos
						.where('id_photo')
						.equals(ds_options.params.id)
						.first()
						.then((photo) => {
							data.value.name = photo.name
							data.value.frame = photo.frame
							data.value.taken = photo.taken
							data.value.mode = photo.mode
							data.value.aperture = photo.aperture
							data.value.shutter = photo.shutter
							data.value.focal = photo.focal
							data.value.ev = photo.ev
							data.value.id_lens = photo.id_lens
							data.value.metering = photo.metering
							data.value.area = photo.area
							data.value.areas = JSON.parse(photo.areas)
							data.value.filters = JSON.parse(photo.filters)
							data.value.extension = photo.extension
							data.value.flash = photo.flash
							data.value.id_film = photo.id_film
							data.value.id_camera = photo.id_camera
						})
					} catch (error) {
						console.log('Error!')
					}
				}
			})
			
			async function updatePhoto() {
				try {
					await db.photos.update(
						ds_options.params.id,
						{
							name: data.value.name,
							frame: data.value.frame,
							taken: data.value.taken,
							mode: data.value.mode,
							aperture: data.value.aperture,
							shutter: data.value.shutter,
							focal: data.value.focal,
							id_film: data.value.id_film,
							id_camera: data.value.id_camera,
							id_lens: data.value.id_lens,
							ev: data.value.ev,
							metering: data.value.metering,
							area: data.value.area,
							areas: JSON.stringify(data.value.areas),
							filters: JSON.stringify(data.value.filters),
							extension: data.value.extension,
							flash: data.value.flash,
						}
					).then(
						ds_action.updateProcess('photos', 'update')
					)
				} catch (error) {
					console.log('Error!')
				}
				
				document.getElementById('btn__modal_photo_add__close').click();
			}
			
			
			const area = ref({})
			clearArea()
			
			function clearArea() {
				area.value.id = 1
				resetArea()
			}
			
			function resetArea() {
				area.value.description = ''
				area.value.ev = '0 EV'
			}
			
			function addArea() {
				data.value.areas[area.value.id] = {
					id: area.value.id,
					description: area.value.description,
					ev: area.value.ev
				}
				
				area.value.id++
				resetArea()
			}
			
			function removeArea(id) {
				data.value.areas[id] = null
			}
			
			
			watch (data.value, () => {
				disabled.value = (
					data.value.name == null || data.value.name === ''
					|| data.value.frame == null || data.value.frame === ''
					|| data.value.taken == null || data.value.taken === ''
					|| data.value.mode == null || data.value.mode === ''
					|| data.value.aperture == null || data.value.aperture === ''
					|| data.value.shutter == null || data.value.shutter === ''
					|| data.value.focal == null || data.value.focal === ''
				)
			})
			
			watch (ds_active, () => {
				data.value.id_film = ds_active.film.id
			})
			
			
			async function addPhoto() {
				try {
					await db.photos.add({
						name: data.value.name,
						frame: data.value.frame,
						taken: data.value.taken,
						mode: data.value.mode,
						aperture: data.value.aperture,
						shutter: data.value.shutter,
						focal: data.value.focal,
						id_film: data.value.id_film,
						id_camera: data.value.id_camera,
						id_lens: data.value.id_lens,
						ev: data.value.ev,
						metering: data.value.metering,
						area: data.value.area,
						areas: JSON.stringify(data.value.areas),
						filters: JSON.stringify(data.value.filters),
						extension: data.value.extension,
						flash: data.value.flash,
					}).then(
						ds_action.updateProcess('photos', 'add')
					)
				} catch (error) {
					console.log('Error!')
				}
				
				document.getElementById('btn__modal_photo_add__close').click();
			}
			
			function clearForm() {
				data.value.name = null
				data.value.frame = null
				data.value.taken = `${moment(new Date()).format('YYYY-MM-DD')}T${moment(new Date()).format('HH:mm')}`
				data.value.mode = ''
				data.value.aperture = ''
				data.value.shutter = ''
				data.value.focal = ''
				data.value.ev = '0 EV'
				data.value.id_lens = ''
				data.value.metering = ''
				data.value.area = ''
				data.value.areas = []
				data.value.filters = []
				data.value.extension = null
				data.value.flash = ''
				data.value.id_film = ds_active.film.id
				data.value.id_camera = ''
				
				disabled.value = true
			}
			
			function closeForm() {
				clearForm()
				clearArea()
				ds_options.reset()
			}
			
			return { 
				ds,
				ds_active,
				data, addPhoto, disabled,
				db,
				ds_films,
				ds_cameras,
				ds_lenses,
				photo_store,
				closeForm,
				area, addArea, removeArea,
				ds_options,
				updatePhoto,
				lang
			}
		}
	})
</script>