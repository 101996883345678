<template>
	<ContentCameras />
	<div>
		<ModalCamerasAdd />
	</div>
</template>

<script>
	import ContentCameras from "@/components/content/ContentCameras.vue"
	import ModalCamerasAdd from "@/components/modals/ModalCamerasAdd.vue"
	
	import { defineComponent } from 'vue'
	
	export default defineComponent({
		name: 'CamerasView',
		components: {
			ContentCameras,
			ModalCamerasAdd
		}
	})
</script>
